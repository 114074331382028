import { TransportOrder } from '../../../domain/transportOrder';

type GetTransportOrderStatusDto =
    | 'DRAFT'
    | 'CREATED'
    | 'LOADED'
    | 'UNLOADED'
    | 'DONE'
    | 'STORNO';

export type GetTransportOrderDto = {
    id: string;
    orderId: string;
    creationDate: number;
    creator: {
        id: string;
        name: string;
        email: string;
        phoneNumber: string;
    };
    contractor?: {
        id: string;
        name: string;
        nip: string;
        address: string;
        phoneNumber: string;
        email: string;
        contact?: {
            id: string;
            name: string;
            phoneNumber: string;
            email: string;
        }
    };
    driver: {
        name: string;
        phoneNumber: string;
        identityCardNumber: string;
    };
    vehicle: {
        carLicensePlate: string;
        trailerLicensePlate: string;
    }
    loading: Array<{
        date: number;
        endDate: number;
        time: string;
        address: string;
        loadingNumber: string;
        additionalInfo: string;
        completed: boolean;
    }>;
    unloading: Array<{
        date: number;
        endDate: number;
        time: string;
        address: string;
        unloadingNumber: string;
        additionalInfo: string;
        completed: boolean;
    }>;
    loadDetails: Array<{
        name: string;
        value: string;
    }>;
    freight: {
        value: string;
        vatRate: number;
        currency: 'EUR' | 'PLN';
        paymentDays: number;
        clientValue?: string;
    };
    status: GetTransportOrderStatusDto;
    additionalInfo: string;
    comment: string;
}

export const toDomain = (dto: GetTransportOrderDto): TransportOrder => ({
    ...dto,
    creationDate: new Date(dto.creationDate),
    loading: dto.loading.map((loadingItem) => ({
        ...loadingItem,
        date: new Date(loadingItem.date),
        endDate: new Date(loadingItem.endDate),
    })),
    unloading: dto.unloading.map((unloadingItem) => ({
        ...unloadingItem,
        date: new Date(unloadingItem.date),
        endDate: new Date(unloadingItem.endDate),
    }))})

