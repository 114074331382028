import React, { ReactElement } from 'react';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RefreshButton } from '../../../components/RefreshButton';
import { TransportOrderStatusBadge } from '../../../components/TransportOrderStatusBadge';

import { TransportOrder } from '../../../domain/transportOrder';
import { useRefetchTransportOrderData } from '../../../repository/transport-orders/useRefetchTransportOrderData';
import { useTransportOrderRepository } from '../../../repository/transport-orders/useTransportOrderRepository';
import { Error } from '../../Error';
import { OrderId } from '../components/OrderId';
import { TransportOrderFiles } from './transport-order-files/TransportOrderFiles';
import { TransportOrderActionsCard } from './TransportOrderActionsCard';
import { TransportOrderBody } from './TransportOrderBody';
import { TransportOrderLoadingSkeleton } from './TransportOrderLoadingSkeleton';
import { Notification, useTransportOrderNotifications } from './useTransportOrderNotifications';

export const EditTransportOrderWrapper = () => {
    const { transportOrderId } = useParams<{ transportOrderId: string }>();
    const refetchTransportOrder = useRefetchTransportOrderData();

    const {
        data: transportOrder,
        isPending,
        isLoading,
        isError,
        isRefetching,
    } = useTransportOrderRepository(transportOrderId!);

    if (isPending) {
        return <TransportOrderLoadingSkeleton />;
    }

    if (isError || !transportOrder) {
        return <Error message={`Zlecenie o id ${transportOrderId} nie istnieje`} />;
    }

    return <EditTransportOrder
        fetchedTransportOrder={transportOrder}
        reloadTransportOrder={() => refetchTransportOrder(transportOrderId!)}
        isRefreshing={isLoading || isRefetching}
    />;
};

const tabIds = ['details', 'files'];

type TabId = 'details' | 'files';

export type TabItem = {
    id: TabId;
    text: string;
    content: ReactElement;
};

const tabIdFromString = (potentialTabId?: string | null): TabId => {
    if (!potentialTabId || !tabIds.includes(potentialTabId)) {
        return 'details';
    }

    return potentialTabId as TabId;
};

type EditTransportOrderProps = {
    fetchedTransportOrder: TransportOrder;
    reloadTransportOrder: () => void;
    isRefreshing: boolean;
}

const EditTransportOrder = ({ fetchedTransportOrder, reloadTransportOrder, isRefreshing }: EditTransportOrderProps) => {
    const { hash } = useLocation();
    const navigate = useNavigate();

    const { notifications, replaceOrAddNotificationOfType } = useTransportOrderNotifications();

    const transportOrderContractorChangeRelatedNotifications = notifications.filter(({ type }) =>
        type === 'ContractorChangeNotification' || type === 'ContractorOnBlacklist',
    );

    const creationDateAsString = fetchedTransportOrder.creationDate
        .toLocaleDateString()
        .replaceAll('/', '.');

    const activeTabId = tabIdFromString(hash.replaceAll('#', ''));

    const tabs: Array<TabItem> = [
        {
            id: 'details', text: 'Szczegóły', content: <TransportOrderDetails
                fetchedTransportOrder={fetchedTransportOrder}
                addNotification={replaceOrAddNotificationOfType}
            />,
        },
        { id: 'files', text: 'Pliki', content: <TransportOrderFiles fetchedTransportOrder={fetchedTransportOrder} /> },
        // { id: 'history', text: 'Historia', content: <>Tutaj bedzie historia</> },
    ];

    return (
        <>
            <div className="mb-4">
                <div className="d-flex flex-row">
                    <OrderId transportOrder={fetchedTransportOrder} />
                    <RefreshButton
                        isRefreshing={isRefreshing}
                        refreshFunction={reloadTransportOrder}
                    />
                </div>
                <h6 className="text-black-50">Utworzono {creationDateAsString}</h6>
                <TransportOrderStatusBadge status={fetchedTransportOrder.status} />
            </div>

            {transportOrderContractorChangeRelatedNotifications.map(({ id, title, variant, message }) =>
                <Alert key={id} variant={variant} dismissible>
                    <Alert.Heading>{title}</Alert.Heading>
                    {message}
                </Alert>)}

            <Tabs
                mountOnEnter
                unmountOnExit
                className="border-0"
                activeKey={activeTabId}
                onSelect={(eventKey) => navigate(`#${tabIdFromString(eventKey)}`)}
            >
                {tabs.map((tabItem) => (
                    <Tab key={tabItem.id} eventKey={tabItem.id} title={tabItem.text}>
                        {tabItem.content}
                    </Tab>
                ))}
            </Tabs>
        </>
    );
};

const TransportOrderDetails = ({ fetchedTransportOrder, addNotification }: {
    fetchedTransportOrder: TransportOrder;
    addNotification: (newNotification: Notification) => void
}) => {
    const addCreatorChangeNotification = (newNotification: Notification) => {
        addNotification(newNotification);
    };

    return (
        <div className="d-flex flex-row">
            <TransportOrderBody
                fetchedTransportOrder={fetchedTransportOrder}
                addNotification={addCreatorChangeNotification}
                context={{ isTransportOrderEdit: true }}
            />
            <TransportOrderActionsCard transportOrder={fetchedTransportOrder} />
        </div>
    );
};
