import { useEffect, useState } from 'react';

import { Contractor } from '../../domain/contractor';

export type ContractorType = {
    id: { value?: string },
    name: { value: string, setValue: (value: string) => void },
    address: { value: string, setValue: (value: string) => void },
    nip: { value: string, setValue: (value: string) => void },
    email: { value: string, setValue: (value: string) => void },
    phoneNumber: { value: string, setValue: (value: string) => void },
    additionalInfo: { value: string, setValue: (value: string) => void },
    blacklist: { value: boolean, setValue: (value: boolean) => void },
    contacts: { value: Contractor['contacts'], setValue: ((value: Contractor['contacts']) => void) },
}

export const useContractorForm = (contractor?: Contractor): ContractorType => {
    const [name, setName] = useState(contractor?.name || '');
    const [address, setAddress] = useState(contractor?.address || '');
    const [nip, setNip] = useState(contractor?.nip || '');
    const [email, setEmail] = useState(contractor?.email || '');
    const [phoneNumber, setPhoneNumber] = useState(contractor?.phoneNumber || '');
    const [additionalInfo, setAdditionalInfo] = useState(contractor?.additionalInfo || '');
    const [blacklist, setBlacklist] = useState(contractor?.blacklist || false);
    const [contacts, setContacts] = useState(contractor?.contacts || []);

    useEffect(() => {
        if (contractor) {
            setName(contractor.name);
            setAddress(contractor.address);
            setNip(contractor.nip);
            setEmail(contractor.email);
            setPhoneNumber(contractor.phoneNumber);
            setAdditionalInfo(contractor.additionalInfo);
            setBlacklist(contractor.blacklist);
            setContacts(contractor.contacts);
        }
    }, [contractor])

    return {
        id: { value: contractor?.id },
        name: { value: name, setValue: (value) => setName(value) },
        address: { value: address, setValue: (value) => setAddress(value) },
        nip: { value: nip, setValue: (value) => setNip(value) },
        email: { value: email, setValue: (value) => setEmail(value) },
        phoneNumber: { value: phoneNumber, setValue: (value) => setPhoneNumber(value) },
        additionalInfo: { value: additionalInfo, setValue: (value) => setAdditionalInfo(value) },
        blacklist: { value: blacklist, setValue: (value) => setBlacklist(value) },
        contacts: { value: contacts, setValue: (value) => setContacts(value) },
    };
};
