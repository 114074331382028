import { Link } from 'react-router-dom';
import { TransportOrder } from '../domain/transportOrder';

type ContractorContactDetailsProps = {
    contractor: NonNullable<TransportOrder['contractor']>
}

const ContractorContactDetails = ({ contractor }: ContractorContactDetailsProps) => {
    if (contractor.contact && (contractor.contact.name || contractor.contact.email || contractor.contact.phoneNumber)) {
        const { name, email, phoneNumber } = contractor.contact;

        return <p className="mb-0 fst-italic text-secondary text-wrap">
            {phoneNumber ? <span className="text-nowrap">{phoneNumber}</span> : ''}
            {name ? <span>{phoneNumber ? `, ${name}` : name}</span> : ''}
            {email ? <span>{name || phoneNumber ? `, ${email}` : email}</span> : ''}
        </p>;
    }

    const { email, phoneNumber } = contractor;

    if (!email || !phoneNumber) {
        return null;
    }

    return <p className="mb-0 fst-italic text-secondary text-wrap">
        {phoneNumber ? <span className="text-nowrap">{phoneNumber}</span> : ''}
        {email ? <span>{phoneNumber ? `, ${email}` : ''}</span> : ''}
    </p>;
};

type TransportOrderContractorLinkWithContactDetailsProps = {
    contractor: NonNullable<TransportOrder['contractor']>
}

export const TransportOrderContractorLinkWithContactDetails = ({ contractor }: TransportOrderContractorLinkWithContactDetailsProps) => (
    <>
        <Link className="text-decoration-none" onClick={e => e.stopPropagation()}
              to={`/contractors/${contractor.id}`}>
            {contractor.name}{'\n'}
        </Link>
        <ContractorContactDetails contractor={contractor} />
    </>
);