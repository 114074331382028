import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TransportOrderStatus } from '../../domain/transportOrder';
import { useAuthentication } from '../../modules/auth/AuthenticationProvider';
import { BACKEND_URL } from '../backend-url';
import { TRANSPORT_ORDERS_PATH } from '../paths';
import { GetTransportOrderDto, toDomain } from './dto/getTransportOrderDto';
import { onSuccessfulTransportOrderChange } from './onSuccessfulTransportOrderChange';

type ChangeTransportOrderStatusRepositoryMutationType = {
    transportOrderId: string;
    newStatus: TransportOrderStatus;
}

export const useChangeTransportOrderStatusRepository = () => {
    const { getJwtToken } = useAuthentication();
    const queryClient = useQueryClient();

    const changeTransportOrderStatus = useMutation({
        mutationFn: (variable: ChangeTransportOrderStatusRepositoryMutationType) =>
            changeTransportOrderStatusFunction(variable.transportOrderId, variable.newStatus),
        onSuccess: (changedTransportOrder) => onSuccessfulTransportOrderChange(changedTransportOrder, queryClient),
    })

    const changeTransportOrderStatusFunction = async (transportOrderId: string, newStatus: TransportOrderStatus) => {
        const token = await getJwtToken();

        const result = await fetch(`${BACKEND_URL}/${TRANSPORT_ORDERS_PATH}/${transportOrderId}`, {
            method: 'PATCH',
            body: JSON.stringify({ status: newStatus }),
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const dto = await result.json() as GetTransportOrderDto;

        return toDomain(dto);
    };

    return {
        changeTransportOrderStatus: (variable: ChangeTransportOrderStatusRepositoryMutationType) => changeTransportOrderStatus.mutateAsync(variable),
    };
};