import { useState } from 'react';
import { MonthPicker } from './MonthPicker';

type UseMonthPickerProps = {
    initialMonth?: number;
    initialYear?: number;
    onMonthYearChange: (month: number, year: number) => void;
}

export const useMonthPicker = ({ initialMonth = 0, initialYear = 0, onMonthYearChange }: UseMonthPickerProps) => {
    const [selectedMonth, setSelectedMonth] = useState(initialMonth);
    const [selectedYear, setSelectedYear] = useState(initialYear);

    const handleMonthYearChange = (newMonth: number, newYear: number) => {
        setSelectedMonth(newMonth);
        setSelectedYear(newYear);

        onMonthYearChange(newMonth, newYear);
    };

    return {
        selectedMonth,
        selectedYear,
        MonthPicker: () => <MonthPicker
            month={selectedMonth}
            year={selectedYear}
            onChange={handleMonthYearChange}
        />,
    };
};
