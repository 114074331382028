import { Button, Card, Form, Table } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { FormElement } from '../../components/FormElement';
import { ContractorType } from './useContractorForm';

type ContractorContact = ContractorType['contacts'];
type ContractorContactValues = ContractorContact['value'];
type ContractorContactValue = ContractorContactValues['0'];

type ContractorContactsProps = {
    contacts: ContractorContact;
}

export const ContractorContacts = ({ contacts }: ContractorContactsProps) => {
    const handleContactsChange = (changedContacts: ContractorContactValues) => {
        contacts.setValue(changedContacts);
    };
    const handleContactChange = (contactIndex: number, newContact: ContractorContactValue) => {
        const updatedContacts: ContractorContactValues = [
            ...contacts.value.filter((_, index) => index < contactIndex),
            newContact,
            ...contacts.value.filter((_, index) => index > contactIndex),
        ];

        handleContactsChange(updatedContacts);
    };

    const handleContactRemoval = (contactIndex: number) => {
        const updatedContacts: ContractorContactValues = [
            ...contacts.value.filter((_, index) => index < contactIndex),
            ...contacts.value.filter((_, index) => index > contactIndex),
        ];

        handleContactsChange(updatedContacts);
    };

    const handleAddNewContact = () => {
        handleContactsChange([
            ...contacts.value,
            { id: `tmp-${uuidv4()}`, name: '', phoneNumber: '', email: '' },
        ]);
    };

    return <Card className="mb-4">
        <Card.Body>
            <FormElement headingText="Osoby kontaktowe">
                <Table bordered hover>
                    <ContractorContactsTableHead />
                    <tbody>
                    {contacts.value.map((contact, index) => (
                        <ContractorContactItem
                            key={contact.id}
                            contactIndex={index}
                            contact={contact}
                            onChange={(newContact) => handleContactChange(index, newContact)}
                            onRemove={() => handleContactRemoval(index)}
                        />),
                    )}

                    </tbody>
                </Table>
                <div className="text-center">
                    <Button className="p-0 text-center text-decoration-none" variant="link"
                            onClick={handleAddNewContact}>
                        + Dodaj osobę kontaktową
                    </Button>
                </div>
            </FormElement>
        </Card.Body>
    </Card>;
};

type ContractorContactItemProps = {
    contactIndex: number;
    contact: ContractorContactValue;
    onChange: (newContact: ContractorContactValue) => void;
    onRemove: () => void
};

const ContractorContactItem = ({ contactIndex, contact, onChange, onRemove }: ContractorContactItemProps) => {
    const handleNameChange = (value: string) => {
        onChange({ ...contact, name: value });
    };
    const handlePhoneNumberChange = (value: string) => {
        onChange({ ...contact, phoneNumber: value });
    };
    const handleEmailChange = (value: string) => {
        onChange({ ...contact, email: value });
    };

    const handleRemoveButtonClick = () => {
        onRemove();
    };

    return (
        <tr>
            <td className="text-center align-middle">{contactIndex + 1}</td>
            <td><Form.Control className="w-100" type="text" placeholder="Imie i nazwisko"
                              value={contact.name} onChange={(e) => handleNameChange(e.target.value)} /></td>
            <td><Form.Control className="w-100" type="text" placeholder="Numer telefonu"
                              value={contact.phoneNumber} onChange={(e) => handlePhoneNumberChange(e.target.value)} />
            </td>
            <td><Form.Control className="w-100" type="text" placeholder="Email"
                              value={contact.email} onChange={(e) => handleEmailChange(e.target.value)} /></td>
            <td className="width-0"><Button className="text-danger text-decoration-none"
                                            variant="link" onClick={handleRemoveButtonClick}>Usuń</Button>
            </td>
        </tr>
    );
};

const ContractorContactsTableHead = () => (
    <thead>
    <tr>
        <th className="text-center">#</th>
        <th>Imie i nazwisko</th>
        <th>Numer telefonu</th>
        <th>Email</th>
        <th className="width-0"></th>
    </tr>
    </thead>
);
