import { Spinner } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { useContractorRepository } from '../../../repository/contractors/useContractorRepository';
import { useSettingsRepository } from '../../../repository/settings/useSettingsRepository';
import { useTransportOrderRepository } from '../../../repository/transport-orders/useTransportOrderRepository';
import { TransportOrder } from '../../../domain/transportOrder';
import { NewTransportOrder } from './NewTransportOrder';

export const NewTransportOrderWrapper = () => {
    const [queryParams] = useSearchParams();
    const { data } = useSettingsRepository();

    const transportOrderId = queryParams.get('orderId');
    const contractorId = queryParams.get('contractorId');

    if (!data) {
        return <Spinner />
    }

    if (transportOrderId) {
        return <CloneTransportOrder transportOrderId={transportOrderId} />;
    }

    if (contractorId) {
        return <NewTransportOrderForContractor
            contractorId={contractorId}
            initialTransportOrder={{ additionalInfo: data?.transportOrderConfig.defaultAdditionalInfo }}
        />;
    }

    return <NewTransportOrder initialTransportOrder={{ additionalInfo: data?.transportOrderConfig.defaultAdditionalInfo }} />;
};
const CloneTransportOrder = ({ transportOrderId }: { transportOrderId: string }) => {
    const { data: transportOrder } = useTransportOrderRepository(transportOrderId);

    if (!transportOrder) {
        return null;
    }

    const initialTransportOrder: Partial<TransportOrder> = {
        ...transportOrder,
        loading: transportOrder.loading.map((loadingItem) => ({
            ...loadingItem,
            completed: false,
        })),
        unloading: transportOrder.unloading.map((unloadingItem) => ({
            ...unloadingItem,
            completed: false,
        })),
    };

    return <NewTransportOrder initialTransportOrder={initialTransportOrder} />;
};

const NewTransportOrderForContractor = ({ contractorId, initialTransportOrder = {} }: { contractorId: string; initialTransportOrder?: Partial<TransportOrder> }) => {
    const { data: contractor } = useContractorRepository(contractorId);

    if (!contractor) {
        return null;
    }

    const preparedContractor: TransportOrder['contractor'] = {
        id: contractor.id,
        name: contractor.name,
        contact: contractor.contacts[0],
        address: '',
        nip: '',
        phoneNumber: '',
        email: '',
    };

    return <NewTransportOrder initialTransportOrder={{ ...initialTransportOrder, contractor: preparedContractor }} />;

};
