import React, { useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';

import { PageTitle } from '../../components/PageTitle';
import { useSettingsRepository } from '../../repository/settings/useSettingsRepository';
import { CompanyDetails } from './company-details/CompanyDetails';
import { TransportOrderConfig } from './transport-order-pdf-config/TransportOrderConfig';
import { TransportOrderPolicy } from './transport-order-policy/TransportOrderPolicy';
import { Users } from './users/Users';

export const SettingsWrapper = () => {
    const [tabKey, setTabKey] = useState('companyDetails');

    const { data: settings } = useSettingsRepository();

    if (!settings) {
        return null;
    }

    const { companyDetails, transportOrderPolicy, transportOrderConfig } = settings;

    return <>
        <PageTitle>Ustawienia</PageTitle>
        <Card>
            <Card.Header className="border-white d-flex justify-content-between">
                <Tabs activeKey={tabKey} onSelect={(key) => setTabKey(key || '')}>
                    <Tab eventKey="companyDetails" title="Dane firmy" />
                    {/*<Tab eventKey="users" title="Użytkownicy" />*/}
                    <Tab eventKey="policyTab" title="OWU" />
                    <Tab eventKey="transportOrderPdfConfiguration" title="Ustawienia zlecenia transportowego" />
                </Tabs>
            </Card.Header>
            <Card.Body>
                {tabKey === 'companyDetails' &&
                    <CompanyDetails originalCompanyDetails={companyDetails} />}
                {tabKey === 'users' &&
                    <Users />}
                {tabKey === 'policyTab' &&
                    <TransportOrderPolicy originalTransportOrderPolicy={transportOrderPolicy} />}
                {tabKey === 'transportOrderPdfConfiguration' &&
                    <TransportOrderConfig originalTransportOrderConfig={transportOrderConfig} />}
            </Card.Body>
        </Card>
    </>;
};
