import React from 'react';
import { toast } from 'react-toastify';
import { Contractor as ContractorType } from '../../domain/contractor';
import { useModifyContractorRepository } from '../../repository/contractors/useModifyContractorRepository';
import { ContractorButtons } from './ContractorButtons';
import { ContractorContacts } from './ContractorContacts';
import { ContractorDetails } from './ContractorDetails';
import { useContractorForm } from './useContractorForm';
import { useContractorChanged } from './useContractorFormChange';

type ContractorBodyProps = {
    fetchedContractor: ContractorType;
}

export const ContractorBody = ({ fetchedContractor }: ContractorBodyProps) => {
    const contractorForm = useContractorForm(fetchedContractor);
    const contractorChanges = useContractorChanged(contractorForm, fetchedContractor);
    const { modifyContractor } = useModifyContractorRepository();

    const handleSubmitContractorClick = async () => {
        await modifyContractor({
            id: (contractorForm.id.value as string),
            name: contractorForm.name.value,
            nip: contractorForm.nip.value,
            address: contractorForm.address.value,
            email: contractorForm.email.value,
            phoneNumber: contractorForm.phoneNumber.value,
            additionalInfo: contractorForm.additionalInfo.value,
            contacts: contractorForm.contacts.value,
            blacklist: contractorForm.blacklist.value,
        });

        toast.success('Edycja podwykonawcy powiodła się');
    };

    return <div className="d-flex flex-column flex-grow-1 pe-4">
        <ContractorDetails contractor={contractorForm} />
        <ContractorContacts contacts={contractorForm.contacts} />
        <ContractorButtons
            disabled={!contractorChanges.anyChanges}
            onSubmitButtonClick={handleSubmitContractorClick}
            onCancelButtonClick={() => alert('okodować wycofanie zmian na fomularzu')}
        />
    </div>
}