import React from 'react';
import { useRoutes } from 'react-router-dom';
import { useAuthentication } from '../modules/auth/AuthenticationProvider';
import { ClientWrapper as Client } from '../modules/client/Client';
import { Clients } from '../modules/clients/Clients';
import { ContractorWrapper as Contractor } from '../modules/contractor/Contractor';
import { Contractors } from '../modules/contractors/Contractors';
import { Error } from '../modules/Error';
import { Home } from '../modules/home/Home';
import { SettingsWrapper as Settings } from '../modules/settings/Settings';
import { EditTransportOrder as TransportOrder, NewTransportOrder } from '../modules/transport-order';
import { TransportOrders } from '../modules/transport-orders/TransportOrders';
import { ClientOrdersListPage } from '../pages/client-orders-list/ClientOrdersListPage';
import { Sidebar } from './Sidebar';
import { LoggedInUserTopNavbar, NotLoggedInUserTopNavbar } from './TopNavbar';

export const Layout = () => {
    const routes = useRoutes([
        { path: '/', element: <Home />, errorElement: <Error /> },
        { path: '/transport-orders', element: <TransportOrders /> },
        { path: '/new-transport-order', element: <NewTransportOrder /> },
        { path: '/transport-orders/:transportOrderId', element: <TransportOrder /> },
        { path: '/contractors', element: <Contractors /> },
        { path: '/contractors/:contractorId', element: <Contractor /> },
        { path: '/clients', element: <Clients /> },
        { path: '/clients/:clientId', element: <Client /> },
        { path: '/client-orders', element: <ClientOrdersListPage /> },
        { path: '/settings', element: <Settings /> },
    ]);

    const { currentUser } = useAuthentication();

    return <div className="d-flex flex-row">
        <aside className="bg-white h-100vh">
            <Sidebar />
        </aside>
        <main className="d-flex flex-column flex-grow-1 bg-body-tertiary">
            <div>
                {currentUser ? <LoggedInUserTopNavbar /> : <NotLoggedInUserTopNavbar />}
            </div>
            <div className="d-flex flex-grow-1 p-4 flex-column">
                {currentUser && routes}
            </div>
        </main>
    </div>;
};
