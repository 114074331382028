import { MouseEvent, ReactNode, useState } from 'react';
import { Button } from 'react-bootstrap';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';

import { Client } from '../../domain/client';

type ClientLinkProps = {
    className?: string,
    clientId: string,
    children: ReactNode
}
const ClientLink = ({ className, clientId, children }: ClientLinkProps) => (
    <Link
        onClick={e => e.stopPropagation()}
        className={`text-decoration-none ${className}`}
        to={`/clients/${clientId}`}
    >
        {children}
    </Link>
);

type ClientTableRowProps = {
    client: Client,
    index: number,
}

export const ClientTableRow = ({ client, index }: ClientTableRowProps) => {
    const [rowHovered, setRowHovered] = useState(false);
    const navigate = useNavigate();

    const handleMouseEnterRow = () => setRowHovered(true);
    const handleMouseLeavesRow = () => setRowHovered(false);
    const handleRowClick = () => navigate(`/clients/${client.id}`); // trzeba pamietac o e.stopPropagation w innych linkach

    const handleCreateNewTransportOrder = (e: MouseEvent) => {
        e.stopPropagation();
        navigate({
            pathname: '/new-transport-order',
            search: createSearchParams({
                clientId: client.id,
            }).toString(),
        });
    };


    return (
        <tr
            className={`transition ${rowHovered ? 'cursor-pointer' : ''}`}
            onClick={handleRowClick}
            onMouseEnter={handleMouseEnterRow}
            onMouseLeave={handleMouseLeavesRow}
        >
            <td className="text-center">{index + 1}</td>
            <td>
                <ClientLink clientId={client.id}>{client.name}</ClientLink>
            </td>
            <td>{client.address}</td>
            <td>{client.nip}</td>
            <td>{client.phoneNumber}</td>
            <td>{client.email}</td>
            <td className="width-0 text-nowrap">
                <Button variant="link" onClick={handleCreateNewTransportOrder}>
                    Nowe zlecenie
                </Button>
            </td>
        </tr>
    );
};
