import { MouseEvent, ReactNode, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';

import { Contractor } from '../../domain/contractor';


type ContractorLinkProps = {
    className?: string,
    contractorId: string,
    children: ReactNode
}
const ContractorLink = ({ className, contractorId, children }: ContractorLinkProps) => (
    <Link onClick={e => e.stopPropagation()} className={`text-decoration-none ${className}`}
          to={`/contractors/${contractorId}`}>{children}</Link>
);

type ContractorTableRowProps = {
    contractor: Contractor,
    index: number,
}

export const ContractorTableRow = ({ contractor, index }: ContractorTableRowProps) => {
    const [rowHovered, setRowHovered] = useState(false);
    const navigate = useNavigate();

    const handleMouseEnterRow = () => setRowHovered(true);
    const handleMouseLeavesRow = () => setRowHovered(false);
    const handleRowClick = () => navigate(`/contractors/${contractor.id}`); // trzeba pamietac o e.stopPropagation w innych linkach

    const handleCreateNewTransportOrder = (e: MouseEvent) => {
        e.stopPropagation();
        navigate({
            pathname: '/new-transport-order',
            search: createSearchParams({
                contractorId: contractor.id,
            }).toString(),
        });
    };


    return (
        <tr
            className={`transition ${rowHovered ? 'cursor-pointer' : ''}`}
            onClick={handleRowClick}
            onMouseEnter={handleMouseEnterRow}
            onMouseLeave={handleMouseLeavesRow}
        >
            <td className="text-center">{index + 1}</td>
            <td>
                <ContractorLink
                    className={`${contractor.blacklist ? 'text-danger align-baseline' : ''}`}
                    contractorId={contractor.id}>
                    {contractor.blacklist ?
                        <OverlayTrigger placement="top" overlay={
                            <Tooltip id="tooltip-top">
                                Podwykonawca na czarnej liście
                            </Tooltip>}>
                            <span><ExclamationTriangle />{' '}{contractor.name}</span>
                        </OverlayTrigger> :
                        contractor.name
                    }
                </ContractorLink>
            </td>
            <td>{contractor.address}</td>
            <td>{contractor.nip}</td>
            <td>{contractor.phoneNumber}</td>
            <td>{contractor.email}</td>
            <td className="width-0 text-nowrap">
                <Button variant="link" onClick={handleCreateNewTransportOrder}>
                    Nowe zlecenie
                </Button>
            </td>
        </tr>
    );
};
