import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCreateContractorRepository } from '../../repository/contractors/useCreateContractorRepository';
import { ContractorButtons } from '../contractor/ContractorButtons';
import { ContractorContacts } from '../contractor/ContractorContacts';
import { ContractorDetails } from '../contractor/ContractorDetails';
import { useContractorForm } from '../contractor/useContractorForm';

type NewContractorModalProps = {
    show: boolean;
    onClose: () => void;
}
export const NewContractorModal = ({ show, onClose }: NewContractorModalProps) => {
    const navigate = useNavigate();

    const contractor = useContractorForm();
    const { createContractor } = useCreateContractorRepository();

    const handleNewContractorFormSubmit = async () => {
        const newContractorId = await createContractor({
            name: contractor.name.value,
            address: contractor.address.value,
            nip: contractor.nip.value,
            phoneNumber: contractor.phoneNumber.value,
            email: contractor.email.value,
            additionalInfo: contractor.additionalInfo.value,
            contacts: contractor.contacts.value,
        });
        toast.success('Podwykonawca utworzony');

        return navigate(`/contractors/${newContractorId}`);
    };

    return (
        <Modal show={show} size="xl" onHide={onClose}>
            <Modal.Header closeButton className="p-3">
                <Modal.Title>Nowy podwykonawca</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ContractorDetails contractor={contractor} direction="column" />
                <ContractorContacts contacts={contractor.contacts} />
            </Modal.Body>
            <Modal.Footer>
                <ContractorButtons onSubmitButtonClick={handleNewContractorFormSubmit} onCancelButtonClick={onClose} />
            </Modal.Footer>
        </Modal>
    );
};