import { QueryClient } from '@tanstack/react-query';
import { TransportOrder } from '../../domain/transportOrder';

export const onSuccessfulTransportOrderChange = (changedTransportOrder: TransportOrder, queryClient: QueryClient) => {
    reloadTransportOrdersFeed(queryClient);
    updateChangedTransportOrder(changedTransportOrder, queryClient);
};

export const reloadTransportOrdersFeed = (queryClient: QueryClient) => {
    queryClient.refetchQueries({ queryKey: ['transportOrders', 'feed'] });
};

export const updateChangedTransportOrder = (changedTransportOrder: TransportOrder, queryClient: QueryClient) => {
    queryClient.invalidateQueries({ queryKey: ['transportOrders', { id: changedTransportOrder.id }] });
    queryClient.setQueryData(['transportOrders'], (originalTransportOrders?: Array<TransportOrder>) => {
        if (!originalTransportOrders) {
            return;
        }
        return originalTransportOrders.map((originalTransportOrder) => {
            if (originalTransportOrder.id !== changedTransportOrder.id) {
                return originalTransportOrder;
            }
            return changedTransportOrder;
        });
    });
};
