import { TransportOrder } from '../../../domain/transportOrder';
import { TransportOrderFormType } from './useTransportOrderForm';

export type TransportOrderChanges = {
    anyChanges: boolean;
    changes: Array<keyof TransportOrderFormType>;
}

export const useTransportOrderChanged = (formTransportOrder: TransportOrderFormType, originalTransportOrder: TransportOrder): TransportOrderChanges => {
    const checkStringField = (a: string, b: string, name: keyof TransportOrderFormType): Array<keyof TransportOrderFormType> => a !== b ? [name] : [];
    const checkDateField = (a: Date, b: Date, name: keyof TransportOrderFormType): Array<keyof TransportOrderFormType> => a !== b ? [name] : [];
    const checkCreator = (): Array<'creator'> => originalTransportOrder.creator.id !== formTransportOrder.creator.value.id ? ['creator'] : [];
    const checkContractor = (): Array<'contractor'> => {
        const originalContractor = originalTransportOrder.contractor;
        const formContractor = formTransportOrder.contractor.value;

        return originalContractor?.id !== formContractor?.id ||
        originalContractor?.name !== formContractor?.name ||
        originalContractor?.nip !== formContractor?.nip ||
        originalContractor?.address !== formContractor?.address ||
        originalContractor?.email !== formContractor?.email ||
        originalContractor?.phoneNumber !== formContractor?.phoneNumber ||
        originalContractor?.contact?.id !== formContractor?.contact?.id ||
        originalContractor?.contact?.name !== formContractor?.contact?.name ||
        originalContractor?.contact?.email !== formContractor?.contact?.email ||
        originalContractor?.contact?.phoneNumber !== formContractor?.contact?.phoneNumber
            ? ['contractor'] : [];
    };
    const checkDriver = (): Array<'driver'> => {
        const originalDriver = originalTransportOrder.driver;
        const formDriver = formTransportOrder.driver.value;

        return originalDriver.name !== formDriver.name || originalDriver.phoneNumber !== formDriver.phoneNumber || originalDriver.identityCardNumber !== formDriver.identityCardNumber ?
            ['driver'] : [];
    };
    const checkVehicle = (): Array<'vehicle'> => {
        const originalVehicle = originalTransportOrder.vehicle;
        const formVehicle = formTransportOrder.vehicle.value;

        return originalVehicle.carLicensePlate !== formVehicle.carLicensePlate || originalVehicle.trailerLicensePlate !== formVehicle.trailerLicensePlate ?
            ['vehicle'] : [];
    };
    const checkLoading = (): Array<'loading'> => {
        const originalLoading = originalTransportOrder.loading;
        const formLoading = formTransportOrder.loading.value;

        if (originalLoading.length !== formLoading.length) {
            return ['loading'];
        }

        return originalLoading.some((originalLoadingItem, index) => {
            const formLoadingItem = formLoading[index];

            return originalLoadingItem.address !== formLoadingItem.address ||
                compareDates(originalLoadingItem.date, formLoadingItem.date) ||
                compareDates(originalLoadingItem.endDate, formLoadingItem.endDate) ||
                originalLoadingItem.time !== formLoadingItem.time ||
                originalLoadingItem.loadingNumber !== formLoadingItem.loadingNumber ||
                originalLoadingItem.additionalInfo !== formLoadingItem.additionalInfo ||
                originalLoadingItem.completed !== formLoadingItem.completed;

        }) ? ['loading'] : [];
    };
    const checkUnloading = (): Array<'unloading'> => {
        const originalUnloading = originalTransportOrder.unloading;
        const formUnloading = formTransportOrder.unloading.value;

        if (originalUnloading.length !== formUnloading.length) {
            return ['unloading'];
        }

        return originalUnloading.some((originalUnloadingItem, index) => {
            const formUnloadingItem = formUnloading[index];

            return originalUnloadingItem.address !== formUnloadingItem.address ||
                compareDates(originalUnloadingItem.date, formUnloadingItem.date) ||
                compareDates(originalUnloadingItem.endDate, formUnloadingItem.endDate) ||
                originalUnloadingItem.time !== formUnloadingItem.time ||
                originalUnloadingItem.unloadingNumber !== formUnloadingItem.unloadingNumber ||
                originalUnloadingItem.additionalInfo !== formUnloadingItem.additionalInfo ||
                originalUnloadingItem.completed !== formUnloadingItem.completed;
        }) ? ['unloading'] : [];
    };
    const checkLoadDetails = (): Array<'loadDetails'> => {
        const originalLoadDetails = originalTransportOrder.loadDetails;
        const formLoadDetails = formTransportOrder.loadDetails.value;

        const compareItem = (originalItem: TransportOrder['loadDetails'][0], formItem: TransportOrder['loadDetails'][0]) =>
            originalItem.name !== formItem.name || originalItem.value !== formItem.value;

        return originalLoadDetails.length !== formLoadDetails.length || originalLoadDetails.some((originalItem, index) => compareItem(originalItem, formLoadDetails[index])) ?
            ['loadDetails'] : [];
    };
    const checkFreight = (): Array<'freight'> => {
        const originalFreight = originalTransportOrder.freight;
        const formFreight = formTransportOrder.freight.value;

        return originalFreight.value !== formFreight.value ||
            originalFreight.currency !== formFreight.currency ||
            originalFreight.vatRate !== formFreight.vatRate ||
            originalFreight.paymentDays !== formFreight.paymentDays ||
            originalFreight.clientValue !== formFreight.clientValue ?
            ['freight'] : [];
    };

    const changes: Array<keyof TransportOrderFormType> = [
        ...checkStringField(formTransportOrder.id.value, originalTransportOrder.id, 'id'),
        ...checkStringField(formTransportOrder.orderId.value, originalTransportOrder.orderId, 'orderId'),
        ...checkDateField(formTransportOrder.creationDate.value, originalTransportOrder.creationDate, 'creationDate'),
        ...checkCreator(),
        ...checkContractor(),
        ...checkDriver(),
        ...checkVehicle(),
        ...checkLoading(),
        ...checkUnloading(),
        ...checkLoadDetails(),
        ...checkFreight(),
        ...checkStringField(formTransportOrder.status.value, originalTransportOrder.status, 'status'),
        ...checkStringField(formTransportOrder.additionalInfo.value, originalTransportOrder.additionalInfo, 'additionalInfo'),
        ...checkStringField(formTransportOrder.comment.value, originalTransportOrder.comment, 'comment'),
    ];

    return {
        anyChanges: changes.length > 0,
        changes,
    };
};

const compareDates = (left: Date, right: Date) => {
    const leftDay = left.getDay();
    const rightDay = right.getDay();

    const leftMonth = left.getMonth();
    const rightMonth = right.getMonth();

    const leftFullYear = left.getFullYear();
    const rightFullYear = right.getFullYear();

    return leftDay !== rightDay || leftMonth !== rightMonth || leftFullYear !== rightFullYear;
};
